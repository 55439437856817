import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import { store } from './store'
import './styles/style.scss'
import './styles/font.css'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import axios from "axios";
import { createI18n } from 'vue-i18n';
const i18n = createI18n({
  locale: 'en_US',
  // locale: store.state.userInfo.langCode,
  messages: {
    "en_US": require('./lang/en_US')
  }
})
import { Tab, Tabs, Checkbox, CheckboxGroup, Icon, CountDown, Toast, Form, Field, Button, Lazyload, Loading, Image as VanImage, Collapse, CollapseItem } from 'vant';
import 'vant/lib/index.css';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { gtag } from './ga.js'
gtag("config", process.env.VUE_APP_GA4_ID);
const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(i18n)
  .use(Tab)
  .use(Icon)
  .use(Tabs)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(CountDown)
  .use(Toast)
  .use(Form)
  .use(Field)
  .use(Button)
  .use(VanImage)
  .use(Loading)
  .use(Collapse)
  .use(CollapseItem)
  .use(Lazyload, {
    error: require('./assets/pic_456.jpg'),
    loading: require('./assets/pic_456.jpg'),
    attempt: 1, // 加载一屏图片
    preLoad: 1, // 失败尝试次数
  })
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLEMAPKEY,
    },
  })
  .use(store);

// app.config.productionTip = false;
axios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  store.commit("setwarning", {
    string: error.message
  });
  return Promise.reject(error);
})
// 获取response error
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.data) {
    store.commit("setwarning", {
      string: error.response.data.errorMessage,
    });
    return;
  }
  if (error.message == "Network Error") {
    error.message = "Network connection issue"
  }

  if (localStorage.getItem("showUserGuide")) {
    store.commit("setwarning", {
      string: error.message,
    });
  }
  return Promise.reject(error);
})
// app.prototype.$axios = axios

router.isReady().then(() => {
  app.mount('#app');
});