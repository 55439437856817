import { store } from "./store";
const apiDomain = process.env.VUE_APP_API_DOMAIN;
// const apiDomain = "http://192.168.112.30:8080";
const shareUrl = process.env.VUE_APP_SHARE_PAGE_URL;
import { Geolocation } from "@capacitor/geolocation";
import { StatusBar, Style } from "@capacitor/status-bar";
if (store.state.appVersion) {
  StatusBar.setOverlaysWebView({ overlay: true });
}

import { Preferences as Storage } from "@capacitor/preferences";
import { isPlatform, alertController } from "@ionic/vue";
import { Browser } from "@capacitor/browser";

import { Share } from '@capacitor/share';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Network } from '@capacitor/network';
import { Toast } from 'vant';
import { App } from '@capacitor/app';
import { gtag } from './ga.js'
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

import { PushNotifications } from "@capacitor/push-notifications";
export default {
  apiDomain: apiDomain,
  globalMixin: {
    data() {
      return {
        apiConfig: {
          listHomePage: "/listHomePage.do",
          getNewestCampaign: "/getNewestCampaign.do",
          listPromotion: "/listPromotion.do",
          listCampaign: "/listCampaign.do",
          getUserStampCard: "/getUserStampCard.do",
          listUserStamp: "/listUserStamp.do",
          listUserCoupon: "/listUserCoupon.do",
          listGift: "/listGift.do",
          getGiftDetail: "/getGiftDetail.do",
          getUserGiftRedeemCode: "/getUserGiftRedeemCode.do",
          getUserGiftRedeemMsg: "/getUserGiftRedeemMsg.do",
          getUserCollectStampMsg: "/getUserCollectStampMsg.do",
          clearCollectCodeStatus: "/clearCollectCodeStatus.do",
          listUserReservation: "/listUserReservation.do",
          saveUserReservation: "/saveUserReservation.do",
          listStore: "/listStore.do",
          listMerchantArea: "/listMerchantArea.do",
          listUserFriend: "/listUserFriend.do",
          transferStamp: "/transferStamp.do",
          acceptFriend: "/acceptFriend.do",
          deleteFriendRequset: "/deleteFriendRequset.do",
          searchFriendsByName: "/searchFriendsByName.do",
          addFriend: "/addFriend.do",
          getCampaignDetail: "/getCampaignDetail.do",
          register: "/register.do",
          login: "/login.do",
          logout: "/logout.do",
          getUserProfile: "/getUserProfile.do",
          sendDeleteAccountEmail: "/sendDeleteAccountEmail.do",
          updateUserProfile: "/updateUserProfile.do",
          forgetUserPassword: "/forgetUserPassword.do",
          resetUserPassword: "/resetUserPassword.do",
          resendActiveEmail: "/resendActiveEmail.do",
          getMerchantDetail: "/getMerchantDetail.do",
          clearUserReservationCodeStatus: "/clearUserReservationCodeStatus.do",
          getUserReservationMsg: "/getUserReservationMsg.do",
          registerDeviceToken: "/registerDeviceToken.do",
          updateUserIcon: "/updateUserIcon.do",
          listUserStampHistory: "/listUserStampHistory.do",
          listUserRedeemHistory: "/listUserRedeemHistory.do",
          grantUserStamp: "/grantUserStamp.do",
          sendIquiryEmail: "/sendIquiryEmail.do",
          resetRegistrationEamil: "/resetRegistrationEamil.do",
          checkoutUserPassword: "/checkoutUserPassword.do",
          changeUserPassword: "/changeUserPassword.do",
          saveShareData: "/saveShareData.do",
          listHistoryUnReadStatus: "/listHistoryUnReadStatus.do",
          getCouponDetail: "/getCouponDetail.do",
          saveUserCoupon: "/saveUserCoupon.do",
          listCoupon: "/listCoupon.do",
          saveUserAllCoupon: "/saveUserAllCoupon.do",

          getTempUser: "/getTempUser.do",
          sendSms: "/sendSms.do",
          verifySms: "/verifySms.do",
          newRegister: "/newRegister.do",
          uploadUserIcon: "/uploadUserIcon.do",
          newLogin: "/newLogin.do",
          checkIsExistFbAccount: "/checkIsExistFbAccount.do",
          changePhone: "/changePhone.do",
          resendSms: "/resendSms.do",
          registerTempEmailUser: "/registerTempEmailUser.do",
          verifyLoginEmail: "/verifyLoginEmail.do",
          updateUserMarketingInfo: "/updateUserMarketingInfo.do",
          registerTempPhoneUser: "/registerTempPhoneUser.do",
          registerPhysicalCard: "/validatePhysicalCardUser.do",
          getUserMemberTierDetail: "/getUserMemberTierDetail.do",
          getRecipes: "/getRecipes.do",
          getRecipesInfo: "/getRecipesInfo.do",
          getMyGrocerys: "/getMyGrocerys.do",
          createNewPlanRecipes: "/createNewPlanRecipes.do",
          delMyGrocerys: "/delMyGrocerys.do",
          buyMyGrocerys: "/buyMyGrocerys.do",
          exportRecipesInfo: "/exportRecipesInfo.do",
          getMyPlanRecipes: "/getMyPlanRecipes.do",
          planMyRecipes: "/planMyRecipes.do",
          addMyGrocerys: "addMyGrocerys.do",
          validateUser: "/validateUser.do",

          listUserStampCard: "/listUserStampCard.do",
          detailsUserStampCard: "/detailsUserStampCard.do",
          verifySmsForReg: "/verifySmsForReg.do",
          findProfile: "/findProfile.do",
          confirmProfile: "/confirmProfile.do",
          listCouponsAndGift: "/listCouponsAndGift.do",
          redeemUserStampCard: "/redeemUserStampCard.do",
          redeemUserCoupon: "/redeemUserCoupon.do",
          redeemGift: "/redeemGift.do",
          resetUserPasswordByPhone: "/resetUserPasswordByPhone.do",
          listStampCardForHomePage: "/listStampCardForHomePage.do",
          sendVerifyEmail: "/sendVerifyEmail.do",
          verifyEmail: "/verifyEmail.do",
          resetUserPasswordByEmail: "/resetUserPasswordByEmail.do",
          checkIsExistAccount: "/checkIsExistAccount.do",
          checkIsExistUserName: "/checkIsExistUserName.do",

          transferStampByCode: "/transferStampByCode.do",
          searchUser: "/searchUser.do",
          getRecentTransferRecord: "/getRecentTransferRecord.do",
          receiveTransferStamp: "/receiveTransferStamp.do",
          initUserStampCard: "/initUserStampCard.do",
          passkitGoogle: "/passkit/google",
          passkitGetCodeForIos: "/passkit/getCodeForIos",
          passkitIos: "/passkit/ios",
          getTransactionList: "/listTransaction.do",
          bindUserProfile: "/bindUserProfile.do",

          getRecipientsList: "/listRecipients.do",
          getUserRecipients: "/getUserRecipients.do",
          getDonationReport: "/getDonationReport.do",
          saveUserRecipients: "/saveUserRecipients.do",
          refreshToken: "/auth/refreshToken.do",
          likeGift: "/likeGift.do",
          getServerTime: "/getServerTime.do",

          getUserNewRewardNotifi: "/getUserNewRewardNotifi.do",
          listUserReward: "/listUserReward.do",
        },
        TIMEOUT_CODE: 10401,
        apiDomain: apiDomain,
        shareUrl: shareUrl,
        selectNo: "landing",
        loading: null,
        type: ["C", "S"],
        MAX_WIDTH: 670,
        loadingTime: null,
        defaultIcon: require("@/assets/user_default_photo.png"),
        app: {
          title: null,
        },
        showTime: null,
      };
    },
    methods: {
      getAuthObj() {
        const obj = {
          merchantId: store.state.form.merchantDetail.merchantId,
          lang: store.state.lang,
          accessToken: "",
        };
        if (store.state.form.token) {
          obj.accessToken =
            store.state.form.token.tokenType +
            " " +
            store.state.form.token.accessToken;
        } else {
          delete obj["accessToken"];
        }
        return obj;
      },
      async showLoading() {
        if (this.showTime) {
          clearTimeout(this.showTime);
        }
        this.showTime = setTimeout(() => {
          if (this.loadingTime) {
            clearTimeout(this.loadingTime);
          }
          store.state.showLoading = true;
        }, 1000);
      },
      hideLoading() {
        if (this.showTime) {
          clearTimeout(this.showTime);
        }
        if (store.state.showLoading) {
          this.loadingTime = setTimeout(() => {
            store.state.showLoading = false;
          }, 500);
        }
      },
      clickTabEvent(index) {
        this.$store.state.selectNo = index;
      },
      timeoutEvent() {
        this.$store.state.form.token = "";
        this.setStorageEvent();
        this.$router.push({
          path: "/main/landing",
        });
      },
      async getMerchantDetail(callback) {
        const objdata = {
          merchantCode: process.env.VUE_APP_MERCHANT_CODE,
          lang: store.state.lang,
          apiUrl: this.apiConfig.getMerchantDetail,
        };
        const data = await store.dispatch("apiGetEvent", objdata);
        if (!data) {
          return;
        }
        if (data == this.TIMEOUT_CODE) {
          this.timeoutEvent();
          return;
        }
        if (data.data.result.registerRequiredFields) {
          data.data.result.registerRequiredFields =
            data.data.result.registerRequiredFields.split(",");
        }
        if (data.data.result.registerCcOtherLoginMethodField) {
          data.data.result.registerCcOtherLoginMethodField =
            data.data.result.registerCcOtherLoginMethodField.split(",");
        }
        if (data.data.result.registerShowFields) {
          data.data.result.registerShowFields =
            data.data.result.registerShowFields.split(",");
        }
        store.state.form.merchantDetail = data.data.result;
        this.setStorageEvent();
        if (callback) {
          callback();
        }
      },
      checkRequiredField(id) {
        if (store.state.form.merchantDetail.registerRequiredFields) {
          let isHave =
            store.state.form.merchantDetail.registerRequiredFields.find(
              (item) => item == id
            );
          if (isHave) {
            return true;
          }
        }
        return false;
      },
      checkShowField(id) {
        if (store.state.form.merchantDetail.registerShowFields) {
          let isHave = store.state.form.merchantDetail.registerShowFields.find(
            (item) => item == id
          );
          if (isHave) {
            return true;
          }
        }
        return false;
      },
      sendGAEvent(event, category, label, value) {
        if (!this.$store.state.appVersion) {
          category = "W-" + category;
        }
        if (this.$store.state.appVersion) {
          FirebaseAnalytics.logEvent({
            name: event,
            params: {
              content_type: category,
              content_id: label,
              items: [{ name: value }],
            },
          });
        } else {
          gtag('event', event, {
            'event_category': category,
            'event_label': label,
            'value': value
          });
        }
      },
      initFirebase() {
        FirebaseAnalytics.setCollectionEnabled({
          enabled: true,
        });
      },
      sendGAPageView(title) {
        if (this.$store.state.appVersion) {
          FirebaseAnalytics.setScreenName({
            screenName: title,
            nameOverride: this.$route.path,
          });
        } else {
          gtag('event', 'page_view', {
            page_title: title,
            page_path: this.$route.path,
          })
        }
      },
      setGAUserId(id) {
        if (this.$store.state.appVersion) {
          FirebaseAnalytics.setUserId({
            userId: id,
          });
        } else {
          gtag("config", process.env.VUE_APP_GA4_ID, {
            'uid': id
          });
        }
      },
      async getUserPofileEvent(callback) {
        const objdata = this.getAuthObj();
        objdata.isFromLoyalty = false;
        objdata.apiUrl = this.apiConfig.getUserProfile;
        const data = await this.$store.dispatch("apiGetEvent", objdata);
        if (!data) {
          return;
        }
        data.data.result.isPaperReceipt = !data.data.result.isPaperReceipt
        this.setGAUserId(data.data.result.userId)

        this.$store.state.form.userInfo = data.data.result;
        this.$store.state.userInfo = {
          phoneAreaCode: "+61",
          isMarketingInfo: false,
          isPaperReceipt: false,
        };
        this.setStorageEvent();
        this.registerDeviceTokenEvent();
        if (callback) {
          callback();
          return;
        }
        this.$router.push({
          path: "/main/landing",
        });
      },
      formatDate(input, rule) {
        if (!input || !rule) return;
        let symbol;
        if (rule.indexOf(".") > 0) {
          symbol = ".";
        } else if (rule.indexOf("-") > 0) {
          symbol = "-";
        } else if (rule.indexOf("/") > 0) {
          symbol = "/";
        }
        const timeStamp = new Date(input);
        const year = timeStamp.getFullYear();
        const shortYear = year.toString().substring(2);
        let month = timeStamp.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let date = timeStamp.getDate();
        if (date < 10) {
          date = "0" + date;
        }
        const ruleArray = rule.split(/[-. \\/]/);
        let x = "";
        for (let i = 0; i < ruleArray.length; i++) {
          if (ruleArray[i] == "DD" || ruleArray[i] == "dd") {
            x += date + symbol;
          } else if (ruleArray[i] == "YYYY") {
            x += year + symbol;
          } else if (ruleArray[i] == "MM") {
            x += month + symbol;
          } else if (ruleArray[i] == "YY") {
            x += shortYear + symbol;
          }
        }
        return x.slice(0, x.length - 1);
      },
      getTimeStamp(input) {
        return new Date(input).valueOf();
      },
      checkToken(Name, callback) {
        if (!store.state.form.token) {
          store.state.form.registrationBack = Name;
          store.state.firstPage = 1;
          this.$router.push({
            path: "/main/me",
          });
        } else {
          callback();
        }
      },
      getDeviceType() {
        if (isPlatform("ios")) {
          return 1;
        } else if (isPlatform("android")) {
          return 2;
        }
      },
      async setStorageEvent() {
        await Storage.set({
          key: "formData",
          value: JSON.stringify(store.state.form),
        });
      },
      async getStorageEvent() {
        const formData = await Storage.get({
          key: "formData",
        });
        if (formData.value) {
          store.state.form = JSON.parse(formData.value);
          this.setGAUserId(store.state.form.userInfo.userId)
        }
      },
      async removeStorageEvent() {
        await Storage.remove({
          key: "formData",
        });
      },
      async getLocation() {
        await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }).then((res) => {
          store.state.lat = res.coords.latitude;
          store.state.lng = res.coords.longitude;
        });
      },
      async setStatusBarStyleDark() {
        if (this.$store.state.appVersion) {
          StatusBar.setOverlaysWebView({ overlay: true });
          await StatusBar.setStyle({ style: Style.Dark });
        }
      },
      async setStatusBarStyleLight() {
        if (this.$store.state.appVersion) {
          StatusBar.setOverlaysWebView({ overlay: true });
          await StatusBar.setStyle({ style: Style.Light });
        }
      },
      async openInAppBrowser(item) {
        if (item.webUrl && item.bannerType == 1) {
          await Browser.open({ url: item.webUrl });
        } else if (item.video && item.bannerType == 2) {
          this.$router.push({
            path: "/bannerDetail",
            // query:{
            //   video: item.video,
            //   text: item.text,
            //   name: item.name,
            // }
          });
          this.$store.state.form.bannerInfo = {
            video: item.video,
            text: item.text,
            name: item.name,
          };
        } else if (item.coverImg && item.bannerType == 3) {
          this.$router.push({
            path: "/bannerDetail",
          });
          this.$store.state.form.bannerInfo = {
            text: item.text,
            coverImg: item.coverImg,
            name: item.name,
            bannerType: item.bannerType,
          };
        } else if (item.coverImg && item.bannerType == 4) {
          if (!this.$store.state.form.token) {
            this.$router.push({
              path: "/login",
            });
            return;
          }
          this.$router.push({
            path: "/bannerDetail",
          });
          this.$store.state.form.bannerInfo = {
            text: item.text,
            coverImg: item.coverImg,
            name: item.name,
            bannerType: item.bannerType,
          };
        }
      },
      async shareEvent(obj) {
        if (!obj.image) {
          obj.image =
            "https://cdn-tccstd-web-dev.azureedge.net/share/images/app_icon.png";
        }
        const objdata = this.getAuthObj();
        objdata.title = obj.title;
        objdata.image = obj.image;
        objdata.description = obj.description;
        objdata.apiUrl = this.apiConfig.saveShareData;
        const data = await this.$store.dispatch("apiEvent", objdata);
        if (!data) {
          return;
        }
        if (data == this.TIMEOUT_CODE) {
          this.timeoutEvent();
          return;
        }
        this.shareAppEvent(data.data.result.shareDownloadPageUrl, obj);
      },
      async shareAppEvent(url, data) {
        if (data.code) {
          url += "&" + data.code;
        }
        console.log(url);
        await Share.share({
          title: data.title,
          text: data.description,
          url: url,
          dialogTitle: data.title,
        });
      },
      async takePicture() {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          width: 1000,
          height: 1000,
          resultType: CameraResultType.Base64,
        });
        const imageUrl = "data:image/jpg;base64," + image.base64String;

        // Can be set to the src of an image now
        store.state.form.photo = imageUrl;
        this.$router.replace({
          path: "/cropper",
        });
      },
      async getNexwork() {
        Network.addListener("networkStatusChange", (status) => {
          store.state.connected = status.connected;
        });
        const status = await Network.getStatus();
        store.state.connected = status.connected;
      },
      async registerDeviceTokenEvent() {
        if (!this.$store.state.deviceToken) {
          return;
        }
        const objdata = this.getAuthObj();
        objdata.status = 1;

        objdata.deviceToken = this.$store.state.deviceToken;
        objdata.deviceType = this.$store.state.deviceType;
        objdata.apiUrl = this.apiConfig.registerDeviceToken;
        const data = await this.$store.dispatch("apiEvent", objdata);
        if (!data) {
          return;
        }
        if (data == this.TIMEOUT_CODE) {
          this.timeoutEvent();
          return;
        }
      },
      getPushEvent() {
        if (!this.$store.state.appVersion) return;
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
          }
        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
          (token) => {

            console.log('Push registration success, token: ' + token.value);
            this.$store.state.deviceToken = token.value;
          }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener("registrationError", (error) => {
          console.log("Error on registration: " + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            if (this.$store.state.deviceType == 1) {
              var msg = notification.data.aps.alert;
              this.$store.commit("setwarning", {
                string: msg,
              });
            } else {
              this.$store.commit("setwarning", {
                string: notification.body,
              });
            }
          }
        );
        // Method called when tapping on a notification
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification) => {
            this.pushNotiEvent(notification);
            //   {
            //     "actionId": "tap",
            //     "notification": {
            //         "id": "0:1711383600351558%624febf8624febf8",
            //         "data": {
            //             "google.delivered_priority": "normal",
            //             "google.original_priority": "normal",
            //             "pageRedirectType": "7",
            //             "from": "115419499450",
            //             "type": "4",
            //             "badge": "0",
            //             "msgId": "217",
            //             "refId": "42",
            //             "message": "test1",
            //             "collapse_key": "com.cherrypicks.tcc.ritchies.dev"
            //         }
            //     }
            // }
          }
        );
        this.clearPoint();
      },
      clearPoint() {
        if (!this.$store.state.appVersion) return;
        PushNotifications.removeAllDeliveredNotifications();
      },
      async pushNotiEvent(data) {
        console.log("pushData0", data);
        console.log("pushData1", data.notification.data);
        let dataMsg = data.notification.data;
        if (this.$store.state.form.token) {
          this.$store.state.getNotification = dataMsg;
          if (dataMsg.type == 4) {
            if (dataMsg.pageRedirectType == 1) {
              this.$router.push({
                path: "/stampCardDetail/" + dataMsg.refId,
              });
            } else if (dataMsg.pageRedirectType == 6) {
              this.$router.push({
                path: "/couponDetail/" + dataMsg.refId,
              });
            } else if (dataMsg.pageRedirectType == 7) {
              const objdata = this.getAuthObj();
              objdata.pageLimit = 99;
              objdata.pageNum = 0;
              objdata.apiUrl = this.apiConfig.listUserStampCard;
              const data = await this.$store.dispatch("apiGetEvent", objdata);
              if (!data) {
                return;
              }
              if (data == this.TIMEOUT_CODE) {
                this.timeoutEvent();
                return;
              }
              let item = data.data.result.find(
                (item) => dataMsg.refId == item.stampCardId
              );
              if (item) {
                this.$router.push({
                  path: "/collectorCardDetail/" + item.id,
                });
              } else {
                this.$router.push({
                  path: "/main/offers",
                });
              }
            }
          }
        } else {
          this.$router.push({
            path: "/login",
          });
        }
      },
      checkStampNum(stamp) {
        if (stamp > 99) {
          return "99+";
        }
        return stamp;
      },
      async successToast(msg) {
        Toast.success(msg);
      },
      async toastEvent(msg) {
        Toast(msg);
      },
      async successToastLT(msg) {
        Toast.success({ message: msg, className: "longText" });
      },
      timeOutLogout() {
        this.$store.state.form.token = "";
        this.$store.state.form.eCouponList = [];
        this.$store.state.form.stampList = [];
        this.$store.state.form.collectCardList = [];
        this.$store.state.userInfo = {};

        this.setStorageEvent();

        this.$router.replace({
          path: "/main/landing",
        });
      },
      GetRequest(url) {
        if (url.match(new RegExp("http", "g")).length > 1) {
          var uncodeUri = decodeURIComponent(url);
          uncodeUri = uncodeUri.split("://");
          url = uncodeUri[uncodeUri.length - 1];
        }
        var theRequest = new Object();

        if (url.indexOf("?") > -1) {
          var str = url.substring(url.indexOf("?") + 1);
          var strArr = null;
          if (str.indexOf("&amp;") > -1) {
            strArr = str.split("&amp;");
            if (strArr[strArr.length - 1].indexOf("&") > -1) {
              strArr[strArr.length - 1] = strArr[strArr.length - 1].split("&");
              strArr[strArr.length - 1] = strArr[strArr.length - 1][0];
            }
          } else {
            strArr = str.split("&");
          }
          for (var i = 0; i < strArr.length; i++) {
            theRequest[strArr[i].split("=")[0]] = decodeURIComponent(
              strArr[i].split("=")[1]
            );
          }
        }

        return theRequest;
      },
      async receiveTransferStampEvent(code) {
        const objdata = this.getAuthObj();
        objdata.transferCode = code;
        objdata.apiUrl = this.apiConfig.receiveTransferStamp;
        const data = await this.$store.dispatch("apiPutEvent", objdata);
        if (!data) {
          return;
        }
        if (data == this.TIMEOUT_CODE) {
          this.timeoutEvent();
          return;
        }
        if (data.data.result) {
          Toast("Receive Transfer Stamp Success");
        }
      },
      async checkAppLaunchUrl() {
        const { url } = await App.getLaunchUrl();
        // this.toastEvent("App opened with URL:", url);
        let Request = new Object();
        Request = this.GetRequest(url);
        // this.toastEvent("App opened with Data:", JSON.stringify(Request));
        if (this.$store.state.form.token && Request.code) {
          this.receiveTransferStampEvent(Request.code);
        }
      },

      async getAppInfo(bool) {
        try {
          const info = await App.getInfo();
          store.state.appVersion = info.version;
          if (this.$store.state.connected) {
            const objdata = {};
            objdata.apiUrl = process.env.VUE_APP_VERSION + "?rnd=" + Date.now();
            const data = await this.$store.dispatch("jsonEvent", objdata);
            // this.toastEvent(info.version+'  '+data.data.minimum_version)
            if (data && data.data) {
              if (info.version < data.data.minimum_version && bool) {
                this.updateAlert(data.data);
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        } catch (e) {
          return true;
        }
      },
      async getMoreInfo() {
        const objdata = {};
        objdata.apiUrl =
          process.env.VUE_APP_SHARE_URL + "more.json?rnd=" + Date.now();
        // objdata.apiUrl = 'http://192.168.112.92:8080/share/more.json';
        const data = await this.$store.dispatch("jsonEvent", objdata);
        //1, title 2,text 3,video ,4, image
        if (data && data.data && data.data.aboutUs) {
          this.$store.state.moreInfo = data.data;
        }
      },
      async updateAlert(data) {
        const alert = await alertController.create({
          message: data.message,
          buttons: [
            {
              text: "Cancel",
              handler: () => {
                navigator.app.exitApp();
                App.exitApp();
              },
            },
            {
              text: "Okay",
              handler: () => {
                this.openBrowser(data.link);
                App.exitApp();
              },
            },
          ],
        });
        return alert.present();
      },
      async openBrowser(url) {
        await Browser.open({ url: url });
      },
      async getMemberDetail(bool, type) {
        this.cardData = [];
        const objdata = this.getAuthObj();
        objdata.isCallLoyalty = false;
        objdata.apiUrl = this.apiConfig.getUserMemberTierDetail;
        const data = await this.$store.dispatch("apiGetEvent", objdata);
        if (!data) {
          return;
        }
        if (data == this.TIMEOUT_CODE) {
          this.timeoutEvent();
          return;
        }
        this.cardData.push({
          img: data.data.result.tierImg,
          id: "user" + data.data.result.userId,
          name: this.$store.state.form.userInfo.userName,
          qrcode: data.data.result.qrCode,
          data: "",
          type: 0,
        });
        this.$store.state.form.cardData = this.cardData;
        if (bool) {
          return;
        }
        if (this.$store.state.getNotification == null) {
          if (this.$store.state.appVersion) {
            // this.$router.replace({
            //   path: "/main/landing",
            // });
            // setTimeout(() => {
            this.$router.replace({
              path: "/wallet",
              query: { type },
            });
            // }, 100)
          } else {
            if (type == 1) {
              this.$router.replace({
                path: "/main/landing",
                query: {
                  isLogin: 1,
                },
              });
            } else if (type == 2) {
              this.$router.replace({
                path: "/main/landing",
              });
            }
          }
        }
      },
      async refreshTokenEvent() {
        const objdata = this.getAuthObj();
        delete objdata["merchantId"];
        delete objdata["accessToken"];
        objdata.refreshToken = this.$store.state.form.token.refreshToken;
        objdata.nullToken = true;
        objdata.apiUrl = this.apiConfig.refreshToken;
        const data = await this.$store.dispatch("apiEvent", objdata);
        if (!data) {
          return;
        }
        if (data == this.TIMEOUT_CODE) {
          this.timeoutEvent();
          return;
        }
        this.$store.state.form.token = data.data.result;
        this.setStorageEvent();
      },
      formatString(str) {
        var tmp = "";
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) >= 65281 && str.charCodeAt(i) <= 65374) {
            // 如果位于全角！到全角～区间内
            tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
          } else if (str.charCodeAt(i) == 12288) {
            //全角空格的值，它没有遵从与ASCII的相对偏移，必须单独处理
            tmp += " ";
          } else {
            // 不处理全角空格，全角！到全角～区间外的字符
            tmp += str[i];
          }
        }
        return tmp;
      },
    },
  },
};
