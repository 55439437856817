import { createRouter, createWebHashHistory, createWebHistory } from '@ionic/vue-router';
import Main from '../views/main.vue'
import { store } from '../store'

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/register.vue')
  },
  {
    path: '/main/',
    component: Main,
    children: [
      {
        path: '',
        redirect: '/main/landing'
      },
      {
        path: 'landing',
        name: 'landingPage',
        component: () => import('@/views/landing.vue')
      },
      {
        path: 'me',
        name: 'mePage',
        component: () => import('@/views/me.vue')
      },
      {
        path: 'more',
        name: 'MorePage',
        component: () => import('@/views/more.vue')
      },
      {
        path: 'andMore',
        name: 'meAndMore',
        component: () => import('@/views/andMore.vue')
      },
      {
        path: 'offers',
        name: 'OffersPage',
        component: () => import('@/views/offers.vue')
      },
    ]
  },
  {
    path: '/signup',
    name: 'registrationPage',
    component: () => import('@/views/registration.vue')
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/password/:type',
    name: 'passwordPage',
    component: () => import('@/views/password.vue')
  },
  {
    path: '/verifyEmail',
    name: 'verifyEmail',
    component: () => import('@/views/verifyEmail.vue')
  },
  {
    path: '/verifyCode/:type/:registerType',
    name: 'VerificationCode',
    component: () => import('@/views/verifyCode.vue')
  },
  {
    path: '/createAccount',
    name: 'createAccount',
    component: () => import('@/views/createAccount.vue')
  },
  {
    path: '/setProfile',
    name: 'setProfile',
    component: () => import('@/views/setProfile.vue')
  },
  {
    path: '/forgotPassword/:type',
    name: 'forgetPassword',
    component: () => import('@/views/forgotPassword.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('@/views/resetPassword.vue')
  },
  {
    path: '/rewardsHistory',
    name: 'rewardHistory',
    component: () => import('@/views/rewardsHistory.vue')
  },
  {
    path: '/couponDetail/:id',
    name: '/couponDetail/:id',
    component: () => import('@/views/couponDetail.vue')
  },
  {
    path: '/collectorCardDetail/:id',
    name: 'collectorCardDetail',
    component: () => import('@/views/collectorCardDetail.vue')
  },
  {
    path: '/stampCardDetail/:id',
    name: 'stampCardDetail',
    component: () => import('@/views/stampCardDetail.vue')
  },
  {
    path: '/wallet',
    name: 'walletPage',
    component: () => import('@/views/wallet.vue')
  },
  {
    path: '/scanCode',
    name: 'scanCode',
    component: () => import('@/views/scanCode.vue')
  },
  {
    path: '/rewardDetail/:id/:campaignId',
    name: 'rewardDetail',
    component: () => import('@/views/rewardDetail.vue')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/changePassword.vue')
  },
  {
    path: '/test',
    component: () => import('@/views/test.vue')
  },
  {
    path: '/selectFriend',
    name: 'selectFriend',
    component: () => import('@/views/selectFriend.vue')
  },
  {
    path: '/cropper',
    name: 'cropperPage',
    component: () => import('@/views/cropper.vue')
  },
  {
    path: '/editProfile',
    name: 'editProfile',
    component: () => import('@/views/editProfile.vue')
  },
  {
    path: '/changeMobileEmail/:type',
    name: 'changeMobile',
    component: () => import('@/views/changeMobileEmail.vue')
  },
  {
    path: '/storeLocator',
    name: 'storeLocator',
    component: () => import('@/views/storeLocator.vue')
  },
  {
    path: '/TransHistory',
    name: 'transHistory',
    component: () => import('@/views/TransHistory.vue')
  },
  {
    path: '/eStampHistory',
    name: 'eStampHistory',
    component: () => import('@/views/eStampHistory.vue')
  },
  {
    path: '/collectorHistory',
    name: 'collectorHistory',
    component: () => import('@/views/collectorHistory.vue')
  },
  {
    path: '/eCouponHistory',
    name: 'eCouponHistory',
    component: () => import('@/views/eCouponHistory.vue')
  },
  {
    path: '/referral',
    name: 'ReferralPage',
    component: () => import('@/views/referral.vue')
  },
  {
    path: '/userGuide',
    name: 'userGuide',
    component: () => import('@/views/userGuide.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('@/views/faqs.vue')
  },
  {
    path: '/Terms',
    name: 'tAndC',
    component: () => import('@/views/terms.vue')
  },
  {
    path: '/privacy',
    name: 'privacyPage',
    component: () => import('@/views/privacy.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/contactUs.vue')
  },
  {
    path: '/notification',
    name: 'notificationPage',
    component: () => import('@/views/notification.vue')
  },
  {
    path: '/notificationDetail',
    name: 'notificationDetail',
    component: () => import('@/views/notificationDetail.vue')
  },
  {
    path: '/bannerDetail',
    name: 'bannerDetail',
    component: () => import('@/views/bannerDetail.vue')
  },
  {
    path: '/rewardRedeemedDetail',
    name: 'rewardRedeemedDetail',
    component: () => import('@/views/rewardRedeemedDetail.vue')
  },
  {
    path: '/couponUserDetail',
    name: 'couponUserDetail',
    component: () => import('@/views/couponUserDetail.vue')
  },
  {
    path: '/community',
    name: 'communityDonation',
    component: () => import('@/views/community.vue')
  },
]

const router = createRouter({
  history: store.state.appVersion ? createWebHistory(process.env.BASE_URL) : createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
