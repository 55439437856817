<template>
  <ion-app :id="$store.state.isPC ? 'PC' : ''" :class="{ 'letter-ios': $store.state.deviceType == 1 }">
    <ion-router-outlet v-if="isGetDetail" :swipeGesture="false" :animated="true" />
    <ion-loading :is-open="$store.state.showLoading" cssClass="my-custom-class" message="Please wait..."
      :duration="10000"> </ion-loading>
    <ion-modal :animated="false" @didDismiss="$store.state.warning.warningFalg = false"
      :is-open="$store.state.warning.warningFalg" css-class="tcc-modal">
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content warn-content">
            <p class="">{{ $store.state.warning.warningMsg }}</p>
            <div class="btn-box">
              <ion-button style="width: 100%" @click="$store.state.warning.warningFalg = false">OK</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
  </ion-app>
</template>

<script>
import config from "./config";
import {
  IonApp,
  IonRouterOutlet,
  IonLoading,
  IonModal,
  useBackButton,
  IonButton,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { App } from "@capacitor/app";
import $ from "jquery";
import { Keyboard } from "@capacitor/keyboard";
import { SplashScreen } from "@capacitor/splash-screen";

import { moreJson } from "./more.js";
export default defineComponent({
  name: "App",
  mixins: [config.globalMixin],
  components: {
    IonApp,
    IonRouterOutlet,
    IonLoading,
    IonModal,
    IonButton,
  },
  data() {
    return {
      isGetDetail: false,
    };
  },
  methods: {
    async init() {
      await SplashScreen.show({
        showDuration: 2000,
        autoHide: false,
      });
      this.initFirebase();
      this.setGAUserId("");
      await this.getNexwork();
      await this.getAppInfo(false);
      await this.getStorageEvent();
      this.setStatusBarStyleLight()

      if (this.$store.state.connected) {
        await this.getMerchantDetail();
      }
      this.isGetDetail = true;
      this.$router.beforeEach((to, from, next) => {
        next();
        this.clearPoint();
        this.exitNo = 0;
      });

      this.getPushEvent();
      // let testData = {
      //     "actionId": "tap",
      //     "notification": {
      //         "id": "0:1711383600351558%624febf8624febf8",
      //         "data": {
      //             "google.delivered_priority": "normal",
      //             "google.original_priority": "normal",
      //             "pageRedirectType": "7",
      //             "from": "115419499450",
      //             "type": "4",
      //             "badge": "0",
      //             "msgId": "217",
      //             "refId": "43",
      //             "message": "test1",
      //             "collapse_key": "com.cherrypicks.tcc.ritchies.dev"
      //         }
      //     }
      // }
      // this.pushNotiEvent(testData);
      this.$store.state.deviceType = await this.getDeviceType();
      console.log("deviceType: " + this.$store.state.deviceType);
      // this.getPushEvent();
      App.addListener("appUrlOpen", () => {
        // this.toastEvent("App opened with URL");
        this.checkAppLaunchUrl();
      });
      useBackButton(5, (processNextHandler) => {
        if (!this.$store.state.exitApp) {
          processNextHandler();
        }
      });

      useBackButton(10, (processNextHandler) => {
        if (!this.$store.state.exitApp) {
          processNextHandler();
        }
      });
      this.exitNo = 0;
      this.$store.state.moreInfo = moreJson;
      this.getMoreInfo();
      App.addListener("backButton", () => {
        // this.$router.go(-1);
        if (this.$store.state.exitApp) {
          this.exitNo++;
          this.timeout = setTimeout(() => {
            this.exitNo = 0;
          }, 2000);
          if (this.exitNo == 2) {
            App.exitApp();
          } else {
            this.toastEvent("Press again to exit the program");
          }
        }
      });
      if (!this.$store.state.appVersion && !this.$store.state.form.token) {
        this.$router.push({
          path: "/register",
        });
      }
      await SplashScreen.hide();
    },

    keyBoardEvent() {
      Keyboard.addListener("keyboardWillShow", (info) => {
        console.log("keyboard will show with height:", info.keyboardHeight);
      });

      Keyboard.addListener("keyboardDidShow", (info) => {
        console.log("keyboard did show with height:", info.keyboardHeight);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        console.log("keyboard will hide");
      });

      Keyboard.addListener("keyboardDidHide", () => {
        console.log("keyboard did hide");
      });
    },
  },
  async created() {
    if (window.innerWidth > this.MAX_WIDTH) {
      this.$store.state.isPC = true;
    } else {
      this.$store.state.isPC = false;
    }
    $(window).on("resize", () => {
      if (window.innerWidth > this.MAX_WIDTH) {
        this.$store.state.isPC = true;
      } else {
        this.$store.state.isPC = false;
      }
    });
    this.init();
    if (this.$store.state.appVersion) {
      this.keyBoardEvent();
    }
  },
  mounted() { },
});
</script>
<style scoped>
ion-app {
  /*animation of native keyboard show*/
  transition: margin 300ms;
}
</style>
