import { createStore } from "vuex";
import axios from "axios";
import config from "../config";

const setFormData = function (data) {
  const params = new FormData();
  for (const key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      params.append(key, data[key]);
    }
  }
  return params;
};
const formatTime = function (fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
};
const getMonthDays = function (before) {
  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  if (before && month - before <= 0) {
    let mon = 12 + (month - before);
    return formatTime("YYYY-mm-dd", new Date(year - 1, mon, 0));
  } else if (before && month - before > 0) {
    return formatTime("YYYY-mm-dd", new Date(year, month - before, 0));
  } else {
    return formatTime("YYYY-mm-dd", new Date(year, month, 0));
  }
};
export const store = createStore({
  state() {
    return {
      appName: "Ritchies Card App",
      count: 1,
      barHeight: 0,
      lang: "en_US",
      pageTitle: "",
      showLoading: false,
      deviceType: 1,
      connected: true,
      exitApp: false,
      storeName: "",
      isPC: false,
      needVerify: false,
      bannerDetail: "",
      appVersion: "",
      getNotification: null,
      moreInfo: {},
      nowMonthEnd: getMonthDays() + " 23:59:59",
      lastMonthEnd: getMonthDays(1) + " 23:59:59",
      pastMonthEnd: getMonthDays(2) + " 23:59:59",
      lPastMonthEnd: getMonthDays(3) + " 23:59:59",
      nowMonthStart: getMonthDays().substr(0, 8) + "01 00:00:00",
      lastMonthStart: getMonthDays(1).substr(0, 8) + "01 00:00:00",
      pastMonthStart: getMonthDays(2).substr(0, 8) + "01 00:00:00",
      lPastMonthStart: getMonthDays(3).substr(0, 8) + "01 00:00:00",
      deviceHeight: window.screen.height,
      emailPatrn:
        /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
      passwordPatrn:
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()+\-_:;"'?<>,.|/\\]).{8,15}$/,
      phonePatrn: /^[\d]{10}$/,
      lat: "",
      lng: "",
      warning: {
        warningMsg: "",
        warningFalg: false,
        button: [],
      },
      userInfo: {
        phoneAreaCode: "+61",
        isMarketingInfo: false,
        isPaperReceipt: false,
      },
      verifyUserInfo: null,
      deviceToken: "",
      form: {
        token: "",
        photo: "",
        phone: "",
        selectIndex: 0,
        location: {},
        userInfo: {},
        merchantDetail: {},
        bannerInfo: {},
      },
    };
  },
  mutations: {
    async setwarning(state, data) {
      state.warning.warningMsg = data.string;
      state.warning.warningFalg = true;
    },
  },
  actions: {
    async apiEvent(context, data) {
      try {
        config.globalMixin.methods.showLoading();
        const url = data.apiUrl;
        delete data["apiUrl"];
        let domain = `${config.apiDomain}`;
        if (data.apiDomain) {
          domain = data.apiDomain;
          delete data["apiDomain"];
        }
        let result;
        if (data.accessToken && !data.nullToken) {
          const Authorization = data.accessToken;

          delete data["accessToken"];
          result = await axios.post(domain + url, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${Authorization}`,
            },
          });
        } else {
          if (data.nullToken) {
            delete data["nullToken"];
          }
          result = await axios.post(domain + url, data);
        }
        config.globalMixin.methods.hideLoading();
        if (result.data.errorCode === 0) {
          return result;
        } else {
          context.commit("setwarning", {
            string: result.data.errorMessage,
          });
          if (
            result.data.errorCode == 10402 ||
            result.data.errorCode == 10401
          ) {
            return 10401;
          } else if (result.data.errorCode == 10555) {
            return 10555;
          } else {
            return false;
          }
        }
      } catch (err) {
        config.globalMixin.methods.hideLoading();
        return false;
      }
    },
    async apiGetEvent(context, data) {
      try {
        config.globalMixin.methods.showLoading();

        const url = data.apiUrl;
        delete data["apiUrl"];
        let domain = `${config.apiDomain}`;
        if (data.apiDomain) {
          domain = data.apiDomain;
          delete data["apiDomain"];
        }
        let result;
        if (data.accessToken) {
          const Authorization = data.accessToken;

          delete data["accessToken"];
          result = await axios.get(domain + url, {
            params: data,
            headers: {
              "Content-Type": "application/json",
              Authorization: `${Authorization}`,
            },
          });
        } else {
          result = await axios.get(domain + url, { params: data });
        }
        config.globalMixin.methods.hideLoading();
        if (url.search("listHomePage") > -1) {
          return result;
        } else if (result.data.errorCode === 0) {
          return result;
        } else {
          context.commit("setwarning", {
            string: result.data.errorMessage,
          });
          if (
            result.data.errorCode == 10402 ||
            result.data.errorCode == 10401
          ) {
            return 10401;
          } else {
            return false;
          }
        }
      } catch (err) {
        config.globalMixin.methods.hideLoading();

        return false;
      }
    },
    async apiPutEvent(context, data) {
      try {
        config.globalMixin.methods.showLoading();

        const url = data.apiUrl;
        delete data["apiUrl"];
        let domain = `${config.apiDomain}`;
        if (data.apiDomain) {
          domain = data.apiDomain;
          delete data["apiDomain"];
        }
        let result;
        if (data.accessToken) {
          const Authorization = data.accessToken;

          delete data["accessToken"];
          result = await axios.put(domain + url, setFormData(data), {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${Authorization}`,
            },
          });
        } else {
          result = await axios.put(domain + url, setFormData(data));
        }
        config.globalMixin.methods.hideLoading();

        if (result.data.errorCode === 0) {
          return result;
        } else {
          context.commit("setwarning", {
            string: result.data.errorMessage,
          });
          if (
            result.data.errorCode == 10402 ||
            result.data.errorCode == 10401
          ) {
            return 10401;
          } else {
            return false;
          }
        }
      } catch (err) {
        config.globalMixin.methods.hideLoading();

        return false;
      }
    },
    async apiFormEvent(context, data) {
      try {
        config.globalMixin.methods.showLoading();

        const url = data.apiUrl;
        delete data["apiUrl"];
        let domain = `${config.apiDomain}`;
        if (data.apiDomain) {
          domain = data.apiDomain;
          delete data["apiDomain"];
        }
        let result;
        if (data.accessToken) {
          const Authorization = data.accessToken;

          delete data["accessToken"];
          result = await axios.post(domain + url, setFormData(data), {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${Authorization}`,
            },
          });
        } else {
          result = await axios.post(domain + url, setFormData(data));
        }
        config.globalMixin.methods.hideLoading();

        if (result.data.errorCode === 0) {
          return result;
        } else {
          context.commit("setwarning", {
            string: result.data.errorMessage,
          });
          if (
            result.data.errorCode == 10402 ||
            result.data.errorCode == 10401
          ) {
            return 10401;
          } else {
            return false;
          }
        }
      } catch (err) {
        config.globalMixin.methods.hideLoading();
        return false;
      }
    },
    async jsonEvent(context, data) {
      try {
        const url = data.apiUrl;
        delete data["apiUrl"];
        const result = await axios.get(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        return result;
      } catch (err) {
        return false;
      }
    },
  },
});
