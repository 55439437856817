module.exports = {
    general: {
        Yes: "Yes",
        No: "No",
        And: "and",
    },
    register: {
        Registration: 'Registration',
        Youremailaddressis: "Your email address is",
        Yourmobilenumberis: "Your mobile number is",
        alreadyregistered: "already registered",
        Gotologin: "Go to login",
        ConfirmMobileNumber: "Confirm Mobile Number",
        Mobiledonotmatch: "Mobile do not match",
        Emaildonotmatch: "Email do not match",
        ConfirmEmail: "Confirm Email",
        EmailAddress: "Email Address",
        TRPE: "To register please use your email address.An email address can only be used once to set- up an account.If your email address is already been used, please register with your mobile phone number.",
        TRPM: "To register, please enter your mobile phone number.",
        ODAPP: "Or download the app on your phone and login to grab the best deals every day.",
        CreateAccount: "Create Account",
        ResetPassword: "Reset Password",
        Password: "Password",
        characters: "8-15 characters with letters, numbers and special characters, case sensitive",
        ConfirmPassword: "Confirm Password",
        Passwordsdonotmatch: "Passwords do not match",
        AgreeTo: "I have read and agree to the",
        WouldLike: "I would like to receive online specials / promotions comms.",
    },
    login: {
        Login: "Login",
        Email: "Email",
        Or: "or",
        GotoRegister: "Go to Register",
        Mobilenumber: "Mobile number",
        MobileFormatError: "Mobile format is incorrect.",
        EmailFormatError: "Email format is incorrect.",
        ContinueWithEmailText: "For existing members please enter your email address, or if you registered with your mobile phone number, click “Continue with Mobile” below to continue.",
        ContinuewithEmail: "Continue with Email",
        ContinuewithMobile: "Continue with Mobile",
        IfYouRegistered: "If you registered with your mobile phone number, enter the number above, or for existing members, please click “Continue with Email” to enter your email address.",
        IfYouAre: "If you are sharing an email address with your family members, please use your mobile number for registration",
        Continue: "Continue",
    },
    verify: {
        Resend: 'Resend',
        Verify: 'Verify',
        VerificationCode: 'Verification Code',
        PleaseInput: 'Please type the verification code sent to',
    },
    tabBar: {
        Home: "Home",
        Offers: "Offers",
        More: "More",
        Me: "Me",
    },
    landing: {
        ValidTill: "Valid Till",
        CollectorCards: "Collector Cards",
        eCoupon: "eCoupon",
        LoginRegister: `Log in / Register`,

    },
    offers: {
        Offers: "Offers",
        ShopEarn: "Shop & Earn",
        eCoupons: "Rewards",
        eStamps: "eStamps",
        ValidTill: "Valid Till",
        Registernowformoreoffers: "Register now for more offers",
        Comingsoon: "Coming soon",
        EarnComingsoon1: "Our Shop & Earn promotion gives you the chance to earn digital coins each time you shop.",
        EarnComingsoon2: "Currently there isn't a shop & earn promotion running.",
        EarnComingsoon3: "We will update this tab & notify you when a new Shop & Earn program begins.",
        CouponComingsoon1: "eCoupons give you access to even more discounts throughout the store.",
        CouponComingsoon2: "You'll find eCoupons loaded to this page.",
        CouponComingsoon3: "Check back here for live eCoupons.",
        EStampsComingsoon1: "eStamps can be earned toward rewards in-store.",
        EStampsComingsoon2: "Our eStamp program runs for a limited time only.",
        EStampsComingsoon3: "You'll be notified when we have a new eStamp program running.",
    },
    me: {
        Me: "Me",
        MyAccount: "My Account",
        EditProfile: 'Edit Profile',
        TransactionHistory: 'Transaction History',
        CollectorCardHistory: 'Collector Card History',
        eCouponHistory: 'eCoupon History',
        eStampHistory: 'eStamp History',
        RewardsHistory: 'Rewards History',
        ReferaFriend: 'Refer a Friend',
        Changepassword: 'Change password',
        Logout: 'Logout',
        RorL: 'Register or Log In to receive great offers, member only specials, and the opportunity to support your favourite club school or charity.',
        Register: 'Register',
        Login: 'Log in',
    },
    more: {
        More: "More",
        Community: "Community",
        StoreLocator: "Store Locator",
        UserGuide: "User Guide",
        AboutUs: "About Us",
        FAQs: "FAQs",
        TermsConditions: "Terms & conditions",
        PrivacyPolicy: "Privacy policy",
        ContactUs: "Contact Us",

    }

}
