<template>
  <ion-page>
    <ion-tabs ref="ionTabs" @ionTabsDidChange="afterTabChange">
      <div class="main-hr"></div>
      <div class="menuLogo" v-if="$store.state.isPC"><img :src="logo" class="logo" /></div>
      <ion-router-outlet :swipeGesture="false" :animated="true"></ion-router-outlet>
      <ion-tab-bar :slot="$store.state.isPC ? 'top' : 'bottom'" class="bottomMenu">
        <ion-tab-button tab="landing" href="/main/landing">
          <i :class="selectIndex == 'landing' ? 'icon-home-select' : 'icon-home'"></i>
          <ion-label class="label" :class="{ 'letter-ios': $store.state.deviceType == 1 }">{{ $t("tabBar.Home") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="offers" href="/main/offers">
          <i :class="selectIndex == 'offers' ? 'icon-offer-select' : 'icon-offer'"></i>
          <ion-label class="label" :class="{ 'letter-ios': $store.state.deviceType == 1 }">{{ $t("tabBar.Offers") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="me" href="/main/me">
          <i :class="selectIndex == 'me' ? 'icon-me' : 'icon-user'"></i>
          <ion-label class="label" :class="{ 'letter-ios': $store.state.deviceType == 1 }">{{ $t("tabBar.Me") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="more" href="/main/more">
          <i :class="selectIndex == 'more' ? 'icon-more-select' : 'icon-more'"></i>
          <ion-label class="label" :class="{ 'letter-ios': $store.state.deviceType == 1 }">{{ $t("tabBar.More") }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet } from "@ionic/vue";
import $ from "jquery";

import config from "../config";
export default {
  name: "mainPage",
  mixins: [config.globalMixin],
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet },
  data() {
    return {
      selectIndex: "landing",
      logo: require("@/assets/logo.png"),
    };
  },
  // computed: {
  //   _select() {
  //     return this.$store.state.selectNo;
  //   }
  // },
  // watch: {
  //   _select() {
  //     console.log(this.$store.state.selectNo)
  //   }
  // },
  watch: {
    $route: {
      handler(n) {
        setTimeout(() => {
          console.log("route", new Date().getTime());
          let path = n.path;
          if (path == "/main/landing") {
            $("#offers-page").addClass("ion-page-hidden");
            $("#offers-page").css("zIndex", "100");
            $("#more-page").addClass("ion-page-hidden");
            $("#more-page").css("zIndex", "100");
            $("#me-page").addClass("ion-page-hidden");
            $("#me-page").css("zIndex", "100");
            $("#landingPage").removeClass("ion-page-hidden");
            $("#landingPage").css("zIndex", "101");
          } else if (path == "/main/offers") {
            $("#more-page").addClass("ion-page-hidden");
            $("#more-page").css("zIndex", "100");
            $("#landingPage").addClass("ion-page-hidden");
            $("#landingPage").css("zIndex", "100");
            $("#me-page").addClass("ion-page-hidden");
            $("#me-page").css("zIndex", "100");
            $("#offers-page").removeClass("ion-page-hidden");
            $("#offers-page").css("zIndex", "101");
          } else if (path == "/main/me") {
            $("#more-page").addClass("ion-page-hidden");
            $("#more-page").css("zIndex", "100");
            $("#landingPage").addClass("ion-page-hidden");
            $("#landingPage").css("zIndex", "100");
            $("#offers-page").addClass("ion-page-hidden");
            $("#offers-page").css("zIndex", "100");
            $("#me-page").removeClass("ion-page-hidden");
            $("#me-page").css("zIndex", "101");
          } else if (path == "/main/more") {
            $("#offers-page").addClass("ion-page-hidden");
            $("#offers-page").css("zIndex", "100");
            $("#landingPage").addClass("ion-page-hidden");
            $("#landingPage").css("zIndex", "100");
            $("#me-page").addClass("ion-page-hidden");
            $("#me-page").css("zIndex", "100");
            $("#more-page").removeClass("ion-page-hidden");
            $("#more-page").css("zIndex", "101");
          }
        }, 45);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    afterTabChange(e) {
      this.selectIndex = e.tab;
      this.sendGAEvent("Click", "Menu", e.tab);
    },
  },
};
</script>
